// 获取当前时间的前n天函数
export default function getBeforeMonth(num) {
    var end = new Date();
    var start = new Date();
    start.setDate(start.getDate() - num);   //day和month会自动计算到上一个月的
    var startYear = start.getFullYear();
    var startMonth = start.getMonth() + 1 >= 10 ? start.getMonth() + 1 : "0" + (start.getMonth() + 1);
    var startDate = start.getDate() >= 10 ? start.getDate() : "0" + start.getDate();
    var endYear = end.getFullYear();
    var endtMonth = end.getMonth() + 1 >= 10 ? end.getMonth() + 1 : "0" + (end.getMonth() + 1);
    var endDate = end.getDate() >= 10 ? end.getDate() : "0" + end.getDate();
    var startDate1 = startYear + "-" + startMonth + "-" + startDate;
    var endDate1 = endYear + "-" + endtMonth + "-" + endDate;
    return [startDate1,endDate1]
}
